<template>
  <div class="container" style="margin: 0 10px">
    <box title="建议/举报">
      <div style="width: 100%;">
        <van-form validate-first @submit="bindSubmit" label-align="right">
          <van-field readonly clickable name="picker" label="被巡察单位" placeholder="请选择被巡察单位"
            :value="form.inspectedUnit" required :rules="rules.inspectedUnitCode"
            @click="showUnitPicker = true"
          />

          <van-field readonly clickable name="picker" label="提交类型" placeholder="请选择提交类型"
            :value="form.typeName" required :rules="rules.type"
            @click="showTypePicker = true"
          />
          <template v-if="form.type == 1">
            <van-field name="reportedPerson" label="被举报人" placeholder="请输入被举报人"
              v-model="form.reportedPerson" :rules="rules.reportedPerson" :required="required.reportedPerson"
            />

            <van-field name="reportedPersonDuty" label="被举报人职务" placeholder="请输入被举报人职务"
              v-model="form.reportedPersonDuty"
            />
          </template>
          
          <template v-if="form.anonymous == 0">
            <van-field name="whistleblowers" label="举报人姓名" placeholder="请输入举报人姓名"
              v-model="form.whistleblowers" :rules="rules.whistleblowers" :required="required.whistleblowers"
            />

            <van-field name="whistleblowersPhone" type="tel" label="举报人手机号" placeholder="请输入举报人手机号"
              v-model="form.whistleblowersPhone" :rules="rules.whistleblowersPhone" :required="required.whistleblowersPhone"
            />
          </template>
          
          <van-field v-if="form.type == '1'" v-model="form.question" type="textarea"
            name="question" rows="8" label="主要问题" placeholder="请填写主要问题"
            :required="required.question" :rules="rules.question"
          />
          <van-field v-else v-model="form.suggestion" type="textarea"
            name="suggestion" rows="8" label="建言建议" placeholder="请填写建言建议"
            :required="required.suggestion" :rules="rules.suggestion"
          />

          <van-field name="uploader" label="文件上传">
            <template #input>
              <van-uploader max-count="9" v-model="annexList" :after-read="afterReadAnnex"/>
            </template>
          </van-field>

          <div style="margin: 16px;">
            <van-button round block type="danger" native-type="submit">提交</van-button>
          </div>
        </van-form>
      </div>
    </box>

    <van-popup v-model="showUnitPicker" position="bottom">
      <van-picker show-toolbar :columns="unitColumns"
        @confirm="bindUnitConfirm" @cancel="showUnitPicker = false"
      />
    </van-popup>

    <van-popup v-model="showTypePicker" position="bottom">
      <van-picker show-toolbar :columns="typeColumns"
        @confirm="bindTypeConfirm" @cancel="showTypePicker = false"
      />
    </van-popup>

    <!-- <van-dialog v-model="show" title="标题" show-cancel-button>
      <img src="https://img01.yzcdn.cn/vant/apple-3.jpg" />
    </van-dialog> -->
    <van-popup v-model="showNotice" position="bottom" :round="true" :close-on-click-overlay="false" >
      <div class="content">
        <div class="notice-title">反映须知</div>
      
        <div class="notice-content">
          <div class="notice-content__title"> 一、受理范围 </div>
          <div class="notice-content__text">
            <p>1、接收关于被巡视党组织领导班子及其成员，被巡视单位下一级党组织领导班子主要负责人和其他领导干部的问题反映。</p>
            <p>2、接收关于被巡视单位其他方面的问题反映。</p>
          </div>

          <div class="notice-content__title"> 二、反映内容 </div>
          <div class="notice-content__text">
            <p>1、请按照页面样式要求填写各项内容。问题描述应条理清晰、简明扼要，有关证明材料可作为附件提交。</p>
            <p>2、实名反映请填写正式姓名、联系方式和身份信息，匿名反映可以留下联系方式。</p>
          </div>

          <div class="notice-content__title"> 三、注意事项 </div>
          <div class="notice-content__text">
            <p>1、请跟据信访受理范围反映问题，不要重复反映同一问题，不要反映与被巡视范围无关的问题。</p>
            <p>2、反映人应对反映问题的真实性负责，切勿捏造歪曲事实、诬告陷害他人。</p>
            <p>巡视组收到问题反映后，将按照有关规定进行办理，并对反映人信息和填写内容严格保密。</p>
          </div>
        </div>

        <div class="agree">
          <van-checkbox shape="square" v-model="isCkeck" checked-color="#b51414" >
            我已阅读并同意反映须知中的内容
          </van-checkbox>
        </div>

        <div class="btn-box">
          <van-button type="danger" round block :disabled="!isCkeck" @click="bindbtn">同意</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import BASE from "../../utils/base";
import Box from '../supervise/components/Box'
// ali oss
let OSS = require('ali-oss');

export default {
  name: 'PatrolReport',
  components: { Box },
  data() {
    return {
      form: {
        // inspectedUnitCode: '',      // 被巡察单位编码
        // inspectedUnit: '',          // 被巡察单位

        // type: '1',       // 提交类型(1举报，2建言建议)
        // typeName: '举报',

        // reportedPerson: '', // 被举报人
        // reportedPersonDuty: '',   // 被举报人职务

        // whistleblowers: '',       // 举报人
        // whistleblowersPhone: '',    // 举报人电话
        
        // question: '', // 主要问题（类型是举报时提交）
        // suggestion: '',   // 建言建议（类型是建议时提交）

        // annex: '', // 附件

        // anonymous: 1, // 是否匿名（0否，1是）
      },

      // 附件列表
      annexList: [],
      // 上传状态
      uploadStatus: {
        uploading: this.$t('uploadStatus.uploading'),
        failed: this.$t('uploadStatus.failed'),
        done: this.$t('uploadStatus.done')
      },

      // 被巡察单位显示控制
      showUnitPicker: false,
      unitColumns: [],

      // 提交类型
      showTypePicker: false,
      typeColumns: [ { text: '举报', value: '1' }, { text: '建言建议', value: '2' } ],
    
      // 须知
      showNotice: true,
      isCkeck: false
    }
  },
  watch: {
    annexList: {
      handler(newV) {
        const arr = []
        newV.forEach(e => {
          if (e.url !== undefined && e.url !== '') {
            arr.push(e.url)
          }
        });
        this.form.annex = arr.join(',')
      },
      deep: true
    }
  },
  computed: {
    rules() {
      // 是否匿名（0否，1是）
      const anonymous = this.form.anonymous
      // 提交类型(1举报，2建言建议)
      const type = this.form.type
      return {
        inspectedUnitCode: [{ required: true, message: '请选择被巡察单位' }],
        type: [{ required: true, message: '请选择提交类型' }],

        reportedPerson: [{ required: type == 1, message: '请填写被举报人' }],
        reportedPersonDuty: [{ required: false, message: '请填写被举报人职务' }],

        whistleblowers: [{ required: anonymous == 0, message: '请填写举报人' }],
        whistleblowersPhone: [
          { required: anonymous == 0, message: '请填写举报人电话' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '手机号格式不正确'
          }
        ],

        question: [{ required: type == 1, message: '请填写主要问题' }],

        suggestion: [{ required: type == 2, message: '请填写建言建议' }]
      }
    },
    required() {
      // 是否匿名（0否，1是）
      const anonymous = this.form.anonymous
      // 提交类型(1举报，2建言建议)
      const type = this.form.type
      return {
        reportedPerson: type == 1,
        whistleblowers: anonymous == 0,
        whistleblowersPhone: anonymous == 0,
        question: type == 1,
        suggestion: type == 2
      }
    }
  },
  activated() {
    this.showNotice = true,
    this.isCkeck = false
    // type
    const { anonymous = 0 } = this.$route.query
    this.form.anonymous = anonymous

    this.form = {
      inspectedUnitCode: '',      // 被巡察单位编码
      inspectedUnit: '',          // 被巡察单位
      type: '1',       // 提交类型(1举报，2建言建议)
      typeName: '举报',
      reportedPerson: '', // 被举报人
      reportedPersonDuty: '',   // 被举报人职务
      whistleblowers: '',       // 举报人
      whistleblowersPhone: '',    // 举报人电话
      question: '', // 主要问题（类型是举报时提交）
      suggestion: '',   // 建言建议（类型是建议时提交）
      annex: '', // 附件
      anonymous, // 是否匿名（0否，1是）
    }

    this.annexList = []
  },
  mounted() {
    // this.getUnitList()
    this.unitColumns = [
      {
        text: '城关镇', value: '1376363670826377218', children: [
          { text: '良繁中心村', value: '1392306150520336386' },
          { text: '冰湖村', value: '1392305563145809922' },
          { text: '黄鸭坑村', value: '1392305191274622977' },
          { text: '头工中心村', value: '1392305891538841601' },
          { text: '城北路社区', value: '1392305007056596993' },
          { text: '城北村', value: '1392305241102954497' },
          { text: '龙王庙社区', value: '1392305059296653313' },
          { text: '龙王庙西村', value: '1392308348872503298' },
          { text: '龙王庙村', value: '1392305687330762754' },
          { text: '坂干梁村', value: '1392305458770554882' },
          { text: '河南庄子村', value: '1392305517927018498' },
          { text: '城南村', value: '1392305387467386882' },
          { text: '四十户村', value: '1392305148194926594' },
        ]
      },
      {
        text: '阜新街道', value: '1376362467300204546', children: [
          { text: '文化路社区', value: '1392300973704818690' },
          { text: '大桥社区', value: '1392301313489580034' }
        ]
      }
    ]
  },
  methods: {
    bindSubmit(e) {
      const that = this

      if(!this.isCkeck) {
        this.$Toast('参数异常');
        return
      }

      this.axios
        .post(BASE.genUrl("zxSuperviseInspection/add", {}), this.form)
        .then(async res => {
          const { code = 201, message = '' } = res.data
          if (code == 200) {
            that.$Toast({
              message: '操作成功',
              onClose: () => that.$router.go(-1)
            });
          } else {
            that.$Toast(message);
          }
        })

    },
    /**
     * 点击同意按钮
     */
    bindbtn() {
      this.showNotice = false
    },
    /**
     * 附件上传
     * 需要改成oss上传
     */
     afterReadAnnex(file) {
      file.status = 'uploading';
      file.message = this.uploadStatus.uploading;
      // 初始化上传状态 - 失败
      let status = 'failed'
      // 初始化
      file.url = '';

      this.axios
        .post(BASE.genUrl("zxUpload/uploadOSS", {}))
        .then(async res => {
          // 获取数据
          const { accessKeyId, accessKeySecret, securityToken: stsToken, dir } = res.data.result
          // 拼接文件名
          const fileName = dir + (new Date()).valueOf() + '_' + file.file.name;
          // 发送oss
          const r = await new OSS({
            region: process.env.VUE_APP_OSS_REGION,
            accessKeyId,
            accessKeySecret,
            stsToken,
            bucket: process.env.VUE_APP_OSS_BUCKET,
          }).put(fileName, file.file)

          console.log(r)

          const { statusCode, requestUrls } = r.res

          // 判断是否上传成功
          if (statusCode === 200) {
            status = 'done';
            // file.url = requestUrls[0]
            file.url = 'https://videowll.xjiot.link/' + fileName
          }
          // 修改上传状态
          file.status = status
          file.message = this.uploadStatus[status];

        })
    },
    /**
     * 获取被巡察单位列表
     */
    getUnitList() {
      let arr = []
      const that = this
      this.getUnitData('1376362044069765122', res => {
        
        this.unitColumns = res
        res.forEach((e, i) => {
          const { value = '' } = e
          that.getUnitData(value, r => {
            this.unitColumns[i].children = r
            this.unitColumns.push()
          })
        })
      })
    },
    async getUnitData(pid, callBack = () => {}) {
      this.axios
          .get(BASE.genUrl("zxCommAddress/rootList", { pid }))
          .then(res => {
            const d = res.data.result.records.map((e, i) => {
              const { name = '', id = '' } = e
              return {
                text: name,
                value: id
              }
            })
            callBack(d)
          })
    },
    /**
     * 被巡察单位选中
     */
    bindUnitConfirm(e, indexArr) {
      this.form.inspectedUnit = e[1]

      const [ i, j ] = indexArr
      const jd = this.unitColumns[i].value
      const sq = this.unitColumns[i].children[j].value
      this.form.inspectedUnitCode = jd + ',' + sq
      this.showUnitPicker = false
    },
    /**
     * 提交类型
     */
    bindTypeConfirm(e) {
      const { text = '', value = '' } = e
      this.form.type = value
      this.form.typeName = text
      this.showTypePicker = false

      /* 清空字段 */
      if (value == '2') {
        // 建言建议
        this.form.reportedPerson = ''
        this.form.reportedPersonDuty = ''
        this.form.question = ''
      } else {
        // 举报
        this.form.suggestion = ''
      }
    }
  }
}
</script>

<style lang="less" scope>
  .content {
    padding: 16px;
    text-align: left;
  }
  .notice-title {
    font-size: 18px;
    text-align: center;
    padding: 10px 0;
  }
  .notice-content {
    font-size: 14px;
    line-height: 1.8;

    &__title, &__text > p {
      margin-bottom: 10px;
    }

    &__title {
      font-weight: 600;
    }
  }

  .agree{
    font-weight: 600;
    margin-bottom: 16px;
  }
</style>
